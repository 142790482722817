import {all} from 'redux-saga/effects';
import audioSaga from './audio';
import authenticateSaga from './authenticate';
import itemsSaga from './items';

export default function* rootSaga() {

  yield all([

    audioSaga(),
    authenticateSaga(),
    itemsSaga(),
  ]);
};
