import classNames from 'classnames';
import React from 'react';
import * as styles from './Tabs.module.css';

function Tabs({children, className}) {

  return <nav className={classNames(styles.wrap, className)}>{children}</nav>;
}

export default Tabs;
