import {combineReducers} from 'redux';
import audio from './audio';
import authentication from './authentication';
import items from './items';

export default combineReducers({

  audio,
  authentication,
  items,
});
