import React from 'react';
import ListItem from './ListItem';
import * as styles from './List.module.css';

function List({items}) {

  return (
    <ul className={styles.wrap}>
      {items.map(item => <ListItem className={styles.item} item={item} key={item.id}/>)}
    </ul>
  );
}

export default List;
