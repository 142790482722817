import {faBroadcastTower, faMapMarkerQuestion, faMicrophone} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import * as styles from './Dashboard.module.css';
import List from './List';
import Search from './Search';
import TabButton from './TabButton';
import Tabs from './Tabs';

function Dashboard() {

  const items = useSelector(state => state.items.items);
  const processing = useSelector(state => state.items.processing);
  const [query, setQuery] = useState('');
  const [tab, setTab] = useState('');

  useEffect(() => {

      window.scrollTo({behavior: 'smooth', top: 0});
    },
    [tab]);

  return (
    <div className={styles.wrap}>
      <header className={styles.header}>
        <h1>Dashboard</h1>
        <Search className={styles.search} onChange={e => setQuery(e.target.value)} value={query}/>
      </header>
      <Tabs className={styles.tabs}>
        <TabButton active={tab === ''} onClick={() => setTab('')}>All items</TabButton>
        <TabButton active={tab === 'needs-recording'} onClick={() => setTab('needs-recording')}>
          <FontAwesomeIcon icon={faMicrophone}/>
          Needs recording
        </TabButton>
        <TabButton active={tab === 'review'} onClick={() => setTab('review')}>
          <FontAwesomeIcon icon={faMapMarkerQuestion}/>
          For review
        </TabButton>
        <TabButton active={tab === 'published'} onClick={() => setTab('published')}>
          <FontAwesomeIcon icon={faBroadcastTower}/>
          Live in app
        </TabButton>
      </Tabs>
      <section>
        <CSSTransition appear classNames="transition-list" in={!processing && items.length > 0} timeout={300}>
          <List items={items.sort((a, b) => a.id - b.id).filter(item => tab === '' || item.status === tab).filter(item => query.trim() === '' || item.text.toLowerCase().includes(query.toLowerCase()))}/>
        </CSSTransition>
      </section>
    </div>
  );
}

export default Dashboard;
