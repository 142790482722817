import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import * as styles from './Search.module.css';

function Search({className, onChange, value}) {

  return (
    <div className={classNames(styles.wrap, className)}>
      <FontAwesomeIcon icon={faSearch}/>
      <input type="text" className={styles.input} placeholder="Search fragments" value={value} onChange={onChange}/>
    </div>
  );
}

export default Search;
