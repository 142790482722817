const initialState = {

  error: null,
  processing: false,
  signedIn: process.env.NODE_ENV === 'development',
};

export default (state = initialState, action) => {

  switch (action.type) {

    case 'LOGIN_ERROR':
      return {...state, error: action.error, processing: false};

    case 'LOGIN_REQUEST':
      return {...state, error: null, processing: true};

    case 'LOGIN_SUCCESS':
      return {...state, error: null, processing: false, signedIn: true};

    case 'LOGOUT':
      return {...state, error: null, signedIn: false};

    default:
      return state;
  }
};
