const initialState = {

  audio: null,
  unlocked: false,
};

export default (state = initialState, action) => {

  switch (action.type) {

    case 'PLAY_AUDIO_SUCCESS':
      return {...state, audio: action.audio};

    case 'STOP_AUDIO':
      return {...state, audio: null};

    case 'UNLOCK_AUDIO_SUCCESS':
      return {...state, unlocked: true};

    default:
      return state;
  }
};
