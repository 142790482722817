import React from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Analytics from './Analytics';
import * as styles from './App.module.css';
import Dashboard from './Dashboard';
import Header from './Header';
import Login from './Login';
import NotFound from './NotFound';

function App() {

  const isAuthenticated = useSelector(state => state.authentication.signedIn);

  const sharedRoutes = (
    <Route component={NotFound}/>
  );

  return (
    <Router>
      <div className={styles.wrap}>
        {!isAuthenticated && (
          <Switch>
            <Redirect from="/" exact to="/login"/>
            <Route path="/login" render={props => <Login className={styles.login} {...props}/>}/>
            {sharedRoutes}
          </Switch>
        )}
        {isAuthenticated && (
          <>
            <Header/>
            <Switch>
              <Redirect from="/login" to="/"/>
              <Route path="/" exact component={Dashboard}/>
              <Route path="/analytics" component={Analytics}/>
              {sharedRoutes}
            </Switch>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
