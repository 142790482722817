import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../actions';
import * as styles from './Login.module.css';
import {ReactComponent as Logo} from './logo.svg';

function Login({className}) {

  const dispatch = useDispatch();
  const el = useRef(null);
  const error = useSelector(state => state.authentication.error);
  const processing = useSelector(state => state.authentication.processing);
  const [email, setEmail] = useState(process.env.NODE_ENV === 'development' ? 'tim@speaky.ai' : '');
  const [password, setPassword] = useState('');

  useEffect(() => {

      if (error) {

        const el2 = el.current;
        el2.classList.add('animated', 'shake');

        return function cleanup() {

          el2.classList.remove('animated', 'shake');
        }
      }
    },
    [el, error]);

  function handleSubmit(e) {

    e.preventDefault();
    dispatch(login(email, password));
  }

  return (
    <div className={classNames(styles.wrap, className)} ref={el}>
      <header className={styles.header}>
        <Logo className={styles.logo}/>
      </header>
      <form className={styles.form} noValidate onSubmit={handleSubmit}>
        <input onChange={e => setEmail(e.target.value)} placeholder="Email address" type="email" value={email}/>
        <input onChange={e => setPassword(e.target.value)} placeholder="Password" type="password" value={password}/>
        <button className="button" disabled={processing} type="submit">
          {processing ? <FontAwesomeIcon icon={faSpinner} spin/> : 'Sign in'}
        </button>
      </form>
    </div>
  );
}

export default Login;
