import {faBroadcastTower, faMapMarkerQuestion, faMicrophone, faPlay, faPause, faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import WaveSurfer from 'wavesurfer.js';
import {playAudio, publishItem, rejectItem, stopAudio} from '../actions';
import * as styles from './ListItem.module.css';

function ListItem({className, item}) {

  const dispatch = useDispatch();
  const waveSurfer = useRef(null);
  const waveSurferEl = useRef(null);
  const globalAudio = useSelector(state => state.audio.audio);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {

      if (item.audio) {

        waveSurfer.current = WaveSurfer.create({

          barWidth: 1,
          container: waveSurferEl.current,
          cursorWidth: 0,
          height: 45,
          normalize: true,
          progressColor: '#7689eb',
          waveColor: '#cad2e2',
          width: 200,
        });

        waveSurfer.current.on('destroy', () => setPlaying(false));
        waveSurfer.current.on('destroy', () => setReady(false));
        waveSurfer.current.on('destroy', () => setSpinning(false));
        waveSurfer.current.on('error', () => setPlaying(false));
        waveSurfer.current.on('error', () => setSpinning(false));
        waveSurfer.current.on('finish', () => dispatch(stopAudio()));
        waveSurfer.current.on('finish', () => setPlaying(false));
        waveSurfer.current.on('loading', () => setSpinning(true));
        waveSurfer.current.on('pause', () => setPlaying(false));
        waveSurfer.current.on('play', () => setPlaying(true));
        waveSurfer.current.on('ready', () => setReady(true));
        waveSurfer.current.on('ready', () => setSpinning(false));

        waveSurfer.current.load(item.audio);

        return function cleanup() {

          waveSurfer.current.destroy();
        }
      }
    },
    [dispatch, item.audio]);

  useEffect(() => {

      if (globalAudio && globalAudio === item.audio && ready) {

        waveSurfer.current.play();

        return function cleanup() {

          waveSurfer.current.pause();
        }
      }
    },
    [globalAudio, item.audio, ready]);

  // TODO: unset globalAudio on unmount, but only if it matches item.audio

  // useEffect(() => function cleanup() {
  //
  //     if (globalAudio === item.audio)
  //       dispatch(stopAudio());
  //   },
  //   [globalAudio, item.audio]);

  function handlePlay() {

    dispatch(globalAudio === item.audio ? stopAudio() : playAudio(item.audio, true));
  }

  return (
    <li className={classNames(styles.wrap, className)}>
          <span className="circle small">
            {item.status === 'needs-recording' && <FontAwesomeIcon icon={faMicrophone} fixedWidth/>}
            {item.status === 'published' && <FontAwesomeIcon icon={faBroadcastTower} fixedWidth/>}
            {item.status === 'review' && <FontAwesomeIcon icon={faMapMarkerQuestion} fixedWidth/>}
          </span>
      <div className={styles.text}>{item.text}</div>
      {item.audio && (
        <>
          <div className={styles.play}>
            <button className="circle small" onClick={handlePlay}>
              <FontAwesomeIcon fixedWidth
                               icon={playing ? faPause : (spinning ? faSpinner : faPlay)}
                               spin={spinning}/>
            </button>
            <div ref={waveSurferEl} className={styles.waveform}/>
          </div>
          <div className={styles.actions}>
            {item.status === 'review' && (
              <>
                <button className="button transparent" onClick={() => dispatch(rejectItem(item.id))}>Reject</button>
                <button className="button" onClick={() => dispatch(publishItem(item.id))}>Go live</button>
              </>
            )}
          </div>
        </>
      )}
    </li>
  );
}

export default ListItem;
