export const fetchItems = () => ({type: 'FETCH_ITEMS_REQUEST'});
export const fetchItemsError = error => ({type: 'FETCH_ITEMS_ERROR', error});
export const fetchItemsSuccess = items => ({type: 'FETCH_ITEMS_SUCCESS', items});
export const login = (user, password) => ({type: 'LOGIN_REQUEST', user, password});
export const loginError = error => ({type: 'LOGIN_ERROR', error});
export const loginSuccess = token => ({type: 'LOGIN_SUCCESS', token});
export const logout = () => ({type: 'LOGOUT'});
export const playAudio = (audio, isUserInteraction) => ({type: 'PLAY_AUDIO_REQUEST', audio, isUserInteraction});
export const playAudioSuccess = audio => ({type: 'PLAY_AUDIO_SUCCESS', audio});
export const publishItem = id => ({type: 'PUBLISH_ITEM', id}); // TODO: move to saga once async
export const rejectItem = id => ({type: 'REJECT_ITEM', id}); // TODO: move to saga once async
export const stopAudio = () => ({type: 'STOP_AUDIO'});
export const unlockAudioError = () => ({type: 'UNLOCK_AUDIO_ERROR'});
export const unlockAudioSuccess = () => ({type: 'UNLOCK_AUDIO_SUCCESS'});
