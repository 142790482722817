import classNames from 'classnames';
import React from 'react';
import * as styles from './TabButton.module.css';

function TabButton({active, children, className, ...props}) {

  return (
    <button className={classNames(styles.wrap, className, {[styles.active]: active})} {...props}>
      {children}
    </button>
  );
}

export default TabButton;
