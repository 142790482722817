import items from './data/items';

function authenticate(user, password) {

  return new Promise((resolve, reject) => {

    setTimeout(() => {

        if (user === 'tim@speaky.ai' && password === 'test') {

          resolve('asdf-token-1234');
        }

        reject(new Error('Incorrect user or password.'));
      },
      500);
  });
}

function clearItem(item) {

  localStorage.removeItem(item);
}

function fetchItems() {

  return new Promise(resolve => {

    setTimeout(() => resolve(items), 500);
  });
}

function storeItem({token}) {

  localStorage.setItem('token', token);
}

export default {

  authenticate,
  clearItem,
  fetchItems,
  storeItem,
};
