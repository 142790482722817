import {fork, call, take, put, cancel, cancelled} from 'redux-saga/effects';
import * as actions from '../actions';
import Api from '../api';

function* authenticate(user, password) {

  try {

    const token = yield call(Api.authenticate, user, password);

    yield put(actions.loginSuccess(token));
    yield call(Api.storeItem, {token});

    return token;
  }
  catch (ex) {

    yield put(actions.loginError(ex));
  }
  finally {

    if (yield cancelled()) {

      // Put special cancellation handling code here.
    }
  }
}

export default function* root() {

  while (true) {

    const {user, password} = yield take('LOGIN_REQUEST');

    const task = yield fork(authenticate, user, password);
    const action = yield take(['LOGOUT', 'LOGIN_ERROR']);

    if (action.type === 'LOGOUT')
      yield cancel(task);

    yield call(Api.clearItem, 'token');
  }
};
