export default [
  {
    audio: require('../audio/hi-1.mp3'),
    id: 1,
    status: 'published',
    text: 'Hoi! Ik ben Daan van PostNL. Wat kan ik voor je doen?',
  },
  {
    audio: require('../audio/hi-short-1.mp3'),
    id: 2,
    status: 'published',
    text: 'Spreek je mee, zeg het maar.',
  },
  {
    audio: require('../audio/link-account-1.mp3'),
    id: 3,
    status: 'published',
    text: 'Om PostNL te blijven gebruiken, moet ik je PostNL account aan Google koppelen. Is dat goed?',
  },
  {
    audio: require('../audio/no-deliveries-1.mp3'),
    id: 4,
    status: 'published',
    text: 'Ik zie op dit moment geen pakketten in je PostNL account. Vraag het me op een later moment nog een keer, of zoek zelf online met je Track & Trace code. Kan ik nog wat anders voor je doen?',
  },
  {
    audio: require('../audio/location-1.mp3'),
    id: 5,
    status: 'review',
    text: 'Ik moet eerst je huidige locatie ophalen van Google om een PostNL locatie bij jou in de buurt te vinden. Is dat oké?',
  },
  {
    audio: require('../audio/closest-1.mp3'),
    id: 6,
    status: 'published',
    text: 'De dichtstbijzijnde PostNL locatie is op het Centraal Station in Amsterdam. Die is vandaag open tot 20:00 uur. Kan ik nog wat anders voor je doen?',
  },
  {
    audio: null,
    id: 7,
    status: 'needs-recording',
    text: 'Kan ik je nog ergens anders bij helpen?',
  },
];
