import {call, take, put} from 'redux-saga/effects';
import * as actions from '../actions';
import items from '../data/items';

/**
 * Safari requires the first audio playback to be directly from a user interaction.
 */
function* unlock() {

  const temp = new Audio(items.find(item => !!item.audio).audio);

  try {

    yield call(temp.play.bind(temp));
    yield call(temp.pause.bind(temp));

    yield put(actions.unlockAudioSuccess());
  }
  catch (ex) {

    yield put(actions.unlockAudioError(ex));
  }
}

export default function* root() {

  while (true) {

    const {audio, isUserInteraction} = yield take('PLAY_AUDIO_REQUEST');

    if (isUserInteraction)
      yield call(unlock);

    yield put(actions.playAudioSuccess(audio));
  }
};
