import React, {useEffect, useState} from 'react';
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  makeWidthFlexible,
  Sankey,
  VerticalRectSeries,
  YAxis,
} from 'react-vis';
import * as styles from './Analytics.module.css';
import TabButton from './TabButton';
import Tabs from './Tabs';

const FlexibleWidthSankey = makeWidthFlexible(Sankey);

function getRandomSeriesData(total) {

  const result = [];
  const mean = Math.random() * 40;

  let lastY = mean;
  let y;

  for (let i = 0; i < total; i++) {

    y = Math.max(Math.random() * mean - mean / 2 + lastY, 0);

    result.push({

      left: i,
      top: y,
    });

    lastY = y;
  }

  return result;
}

function Analytics() {

  const [series, setSeries] = useState([
    {
      color: '#cad2e2',
      data: [],
      title: 'Active users',
    },
    {
      color: '#7689eb',
      data: [],
      title: 'Return users',
    }
  ]);
  const [tab, setTab] = useState('');

  useEffect(() => {

      setSeries(series => {

        series.forEach(s => s.data = getRandomSeriesData(tab === 'month' ? 31 : 7));
        return [...series];
      });
  },
    [tab]);

  const nodes = [

    {name: 'Start'},
    {name: 'Track & Trace'},
    {name: 'Nearest Office'},
    {name: 'Link Account'},
    {name: 'Enable Location'},
    {name: 'Anything Else?'},
    // {name: 'End'},
  ];

  nodes.forEach(node => node.color = '#7689eb');

  const i = name => nodes.findIndex(node => node.name.toLowerCase().startsWith(name));

  const links = [

    {source: i('start'), target: i('nearest'), value: 20},
    {source: i('start'), target: i('track'), value: 50},

    {source: i('nearest'), target: i('enable'), value: 15},
    // {source: i('nearest'), target: i('end'), value: 5},

    // {source: i('track'), target: i('end'), value: 20},
    {source: i('track'), target: i('link'), value: 30},

    {source: i('enable'), target: i('anything'), value: 10},
    // {source: i('enable'), target: i('end'), value: 5},

    {source: i('link'), target: i('anything'), value: 20},
    // {source: i('link'), target: i('end'), value: 10},

    // {source: i('anything'), target: i('end'), value: 25},
  ];

  links.forEach(link => link.color = '#cad2e2');

  return (
    <div className={styles.wrap}>
      <header>
        <h1>Analytics</h1>
      </header>
      <Tabs className={styles.tabs}>
        <TabButton active={tab === ''} onClick={() => setTab('')}>This week</TabButton>
        <TabButton active={tab === 'month'} onClick={() => setTab('month')}>This month</TabButton>
      </Tabs>
      <div className={styles.row}>
        <section>
          <h2 className={styles.title}>Session flow</h2>
          <div>
            <FlexibleWidthSankey
              height={300}
              labelRotation={45}
              links={links}
              nodePadding={50}
              nodes={nodes}
            />
          </div>
        </section>
      </div>
      <div className={styles.row}>
        <section>
          <h2 className={styles.title}>Users</h2>
          <div>
            <FlexibleWidthXYPlot
              animation
              getX={d => d.left}
              getY={d => d.top}
              height={300}
              xDomain={[-0.5, series[0].data.length - 1]}
            >
              <HorizontalGridLines/>
              <YAxis tickSizeInner={0} tickSizeOuter={8}/>
              {/*<XAxis tickSizeInner={0} tickSizeOuter={8}/>*/}
              <VerticalRectSeries
                color={series[0].color}
                data={series[0].data.map(({left, top}) => ({
                  x0: left - 0.5,
                  left: left + 0.5,
                  top
                }))}
                stroke="white"
              />
              <LineSeries
                color={series[1].color}
                curve="curveMonotoneX"
                data={series[1].data}
              />
            </FlexibleWidthXYPlot>
          </div>
          <DiscreteColorLegend items={series} orientation="horizontal"/>
        </section>
        <section>
          <h2 className={styles.title}>Ratings</h2>
          <div>
            <FlexibleWidthXYPlot
              animation
              getX={d => d.left}
              getY={d => d.top}
              height={300}
              xDomain={[-0.5, series[0].data.length - 1]}
            >
              <HorizontalGridLines/>
              <YAxis tickSizeInner={0} tickSizeOuter={8}/>
              {/*<XAxis tickSizeInner={0} tickSizeOuter={8}/>*/}
              <VerticalRectSeries
                color={series[0].color}
                data={series[0].data.map(({left, top}) => ({
                  x0: left - 0.5,
                  left: left + 0.5,
                  top
                }))}
                stroke="white"
              />
              <LineSeries
                color={series[1].color}
                curve="curveMonotoneX"
                data={series[1].data}
              />
            </FlexibleWidthXYPlot>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Analytics;
