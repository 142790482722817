import {faSignOut} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {logout} from '../actions';
import * as styles from './Header.module.css';
import {ReactComponent as Logo} from './logo.svg';

function Header() {

  const dispatch = useDispatch();
  const navLinkProps = {activeClassName: styles.active, className: styles['nav-item']};

  return (
    <header className={styles.wrap}>
      <Logo className={styles.logo}/>
      <nav className={styles.nav}>
        <NavLink {...navLinkProps} to="/" exact>Dashboard</NavLink>
        <NavLink {...navLinkProps} to="/analytics">Analytics</NavLink>
      </nav>
      <button className={classNames('circle', styles.profile)} onClick={() => dispatch(logout())}>
        <FontAwesomeIcon icon={faSignOut}/>
      </button>
    </header>
  );
}

export default Header;
