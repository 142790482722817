import {call, put} from 'redux-saga/effects';
import * as actions from '../actions';
import Api from '../api';

function* fetch() {

  yield put(actions.fetchItems());

  try {

    yield put(actions.fetchItemsSuccess(yield call(Api.fetchItems)));
  }
  catch (ex) {

    yield put(actions.fetchItemsError(ex));
  }
}

export default function* root() {

  yield call(fetch);
};
