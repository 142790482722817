const initialState = {

  error: null,
  processing: false,
  items: [],
};

export default (state = initialState, action) => {

  switch (action.type) {

    case 'FETCH_ITEMS_ERROR':
      return {...state, error: action.error, processing: false};

    case 'FETCH_ITEMS_REQUEST':
      return {...state, error: null, processing: true};

    case 'FETCH_ITEMS_SUCCESS':
      return {...state, error: null, items: action.items, processing: false};

    case 'PUBLISH_ITEM':
      return {...state, items: [...state.items.filter(item => item.id !== action.id), Object.assign({}, state.items.find(item => item.id === action.id), {status: 'published'})]};

    case 'REJECT_ITEM':
      return {...state, items: [...state.items.filter(item => item.id !== action.id), Object.assign({}, state.items.find(item => item.id === action.id), {status: 'needs-recording'})]};

    default:
      return state;
  }
};
